// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { PeopleState } from './types';
import { RootState } from '../../types';

export const state: PeopleState = {
  peopleByImageList: {
    people: undefined,
    selectedPeople: undefined,
    filters: {
      selectedScore: 0.95,
      page: 1,
      itemsPerPage: 8,
    },
    file: {
      faces: undefined,
      selectedFaceIndex: -1,
      image: '',
      name: '',
    },
  },
  peoplePreviewList: { total: 0, items: [] },
  person: {
    isLoading: false,
    data: undefined,
    tags: [],
    error: undefined,
  },
  people: [],
  personIteratorSettings: undefined,
  peopleDownloadState: { isLoading: false, error: undefined },
  personFalseNegatives: [],
};

const namespaced = false;

export const people: Module<PeopleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
