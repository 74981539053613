// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { NotificationState } from './types';
import { RootState } from '../../types';

export const state: NotificationState = {
  notificationPreviewPage: { total: 0, items: [] },
  notifications: [],
  notificationIteratorSettings: undefined,
  notificationsDownloadState: { isLoading: false, error: undefined },
};

const namespaced = false;

export const notifications: Module<NotificationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
