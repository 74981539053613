// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import {
  PeopleFlowSettings, UnitsState, Unit, UnitsPreviewPage, VideoPreview, UnitsIteratorSettings,
} from './types';
import { RootState } from '../types';

export const getters: GetterTree<UnitsState, RootState> = {
  units(state): Unit[] {
    return state.units;
  },
  getUnits: (state): Unit[] => state.units,
  getUnitsSortedByName: (state): Unit[] => {
    const unitsSortedByName = [...state.units];

    unitsSortedByName.sort((currentUnit, nextUnit) => (
      currentUnit.name.toLowerCase().localeCompare(nextUnit.name.toLowerCase())
    ));

    return unitsSortedByName;
  },
  getUnit: (state) => (unitId: string): Unit | undefined => (
    state.units.find((p) => p.id === unitId)
  ),
  unitsPage(state): UnitsPreviewPage {
    return state.unitsPage;
  },
  getUnitFromPage: (state) => (unitId: string): Unit | undefined => (
    state.unitsPage.items.find((p) => p.id === unitId)
  ),
  getUnitVideos: (state) => (unitId: string): VideoPreview[] | undefined => {
    const unit = state.units.find((p) => p.id === unitId);

    return unit?.videos;
  },
  getPeopleFlowSettings: (state) => (unitId: string): PeopleFlowSettings | undefined => {
    const unit = state.units.find((p) => p.id === unitId);

    return unit?.people_flow_settings;
  },
  unitsIteratorSettings(state): UnitsIteratorSettings | undefined {
    return state.unitsIteratorSettings;
  },
  isDownloadingUnits(state): boolean {
    return state.unitsDownloadState.isLoading;
  },
  unitsDownloadError(state): AxiosError | undefined {
    return state.unitsDownloadState.error;
  },
};

export default getters;
