// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import { RootState } from '../../types';
import { NotificationState, History, Notification } from './types';

export const getters: GetterTree<NotificationState, RootState> = {
  getNotifications: (state) => state.notificationPreviewPage,
  getNotificationHistory: (state) => (notificationId: string): History[] | undefined => {
    const notification = state.notifications.find(({ id }) => id === notificationId);

    return notification?.history;
  },
  getNotification: (state) => (notificationId: string): Notification | undefined => (
    state.notifications.find(({ id }) => id === notificationId)
  ),
  getNotificationIteratorSettings: (state) => state.notificationIteratorSettings,
  isDownloadingNotifications(state): boolean {
    return state.notificationsDownloadState.isLoading;
  },
  notificationsDownloadError(state): AxiosError | undefined {
    return state.notificationsDownloadState.error;
  },
};

export default getters;
