<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '30%'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-6 py-8"
      variant="elevated"
      :loading="isLoading"
      width="100%"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.myWorkspaces') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          :icon-name="icons.mdiClose"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="close"
        />
      </v-toolbar>

      <v-card-text v-if="getWorkspaceList.length > 0">
        <v-list>
          <v-list-item
            v-for="(item, index) in getWorkspaceList"
            :key="index"
            @click="workspaceSelection(item)"
          >
            <v-avatar
              size="32"
              color="primary"
            >
              <v-img
                v-if="item.profile_image"
                :src="item.profile_image"
                style="width: inherit; height: inherit;"
                data-dd-privacy="hidden"
              />
              <span
                v-else
                style="color: white"
              >{{ item.name[0] }}</span>
            </v-avatar>
            <span
              class="d-sm-flex mr-2 neutralPrimary--text ml-2 subtitle-2"
            >
              {{ item.name }}
            </span>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="neutral"
          @click="close"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import { mapGetters } from 'vuex';
import SquareButton from './SquareButton.vue';

export default {
  name: 'MyWorkspacesDialog',
  components: {
    SquareButton,
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    icons: {
      mdiClose,
    },
    workspacesList: [],
  }),
  computed: {
    ...mapGetters({
      myWorkspaceList: 'workspace/workspaceList',
    }),
    getWorkspaceList() {
      return this.workspacesList || [];
    },
  },
  watch: {
    myWorkspaceList() {
      this.workspacesList = this.myWorkspaceList.map((item) => item.workspace);
      this.isLoading = false;
    },
  },
  created() {
    if (this.myWorkspaceList.length === 0) {
      this.isLoading = true;
    }
  },
  methods: {
    open() {
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.dialog = false;
    },
    workspaceSelection(item) {
      this.resolve(item);
      this.dialog = false;
    },
  },
};
</script>
