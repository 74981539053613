// Copyright (C) 2020 Deconve Technology. All rights reserved.

import Vue from 'vue';

import './registerServiceWorker';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/apexcharts';
import './plugins/datadog';
import './plugins/moment';
import './plugins/casl';
import './plugins/vue-tour';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('REMEMBER: mobile first!!!! Remove this message after we make this a culture');
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
