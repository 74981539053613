// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { RequestParams } from '@/utils/getDataWithPagination';
import {
  Occurrence,
  OccurrenceFilterSettings,
  OccurrenceState,
  OccurrencePreviewPage,
  types,
} from './types';
import { RootState } from '../../types';
import { dataUrlToBlob } from '../../../utils/data';

import { isCachedDataValid, prepareOccurrenceToBeCached } from './utils';
import { getDemoOccurrence, getDemoOccurrences } from './demo';

interface FilterOccurrenceRequestParams {
  limit: number;
  skip: number;
  search?: string;
  sort_by: string;
  sort_order: string;
  person_id?: string;
  theft_status?: string;
  in_trash?: boolean;
  created_after?: string;
  created_before?: string;
  unit_id?: string;
  workspace_id?: string;
  has_false_negative?: boolean;
}

interface FileInfo {
  name: string;
  file: string;
}

interface FileUploadInfo {
  name: string;
  url_to_upload: string;
}

type FileUploadInfoArray = Array<FileUploadInfo>;

interface ReportParamsAndUrl {
  params: RequestParams;
  url: string;
}

function parseTagsToUrlPattern(prefix: string, noTags: boolean, tags: string[]): string {
  if (noTags) {
    return prefix;
  }

  return tags.map((tag) => `${prefix}=${tag}`).join('&');
}

function getUrlToAddOccurrenceFile(authToken: string,
  occurrenceId: string, fileName: string): Promise<FileUploadInfoArray> {
  return new Promise<FileUploadInfoArray>((resolve, reject) => {
    const newParams = new URLSearchParams();

    newParams.append('files', fileName);

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/occurrences/${occurrenceId}/files/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: authToken,
      },
      params: newParams,
    };

    axios(requestOptions)
      .then((response) => {
        const { data: fileData } = response as { data: FileUploadInfoArray };

        resolve(fileData);
      })
      .catch((error) => reject(error));
  });
}

function addOccurrenceFile(authToken: string,
  urlToUpload: string, fileInfo: FileInfo): Promise<void> {
  return new Promise((resolve, reject) => {
    dataUrlToBlob(fileInfo.file).then((fileBlob) => {
      const file = fileBlob as Blob;

      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        url: urlToUpload,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'attachment',
          Authorization: authToken,
        },
        data: file,
      };

      axios(requestOptions)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
}

function deleteOccurrenceFile(authToken: string,
  occurrenceId: string, fileName: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const newParams = new URLSearchParams();

    newParams.append('files', fileName);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/occurrences/${occurrenceId}/files/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: authToken,
      },
      params: newParams,
    };

    axios(requestOptions)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

function getRequestOccurrencesParamsAndUrl(
  queryOption: OccurrenceFilterSettings,
): ReportParamsAndUrl {
  const {
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    search,
    theftStatus,
    inTrash,
    occurrenceTags,
    noOccurrenceTags,
    personId,
    unitId,
    createdAfter,
    createdBefore,
    workspaceId,
  } = queryOption;

  const skip = itemsPerPage * (page - 1);

  let url = '/faceid/occurrences/';

  const urlParams = parseTagsToUrlPattern('tag_ids', noOccurrenceTags, occurrenceTags);

  if (urlParams.length > 0) {
    url += `?${urlParams}`;
  }

  const params: FilterOccurrenceRequestParams = {
    skip,
    limit: itemsPerPage,
    search,
    // eslint-disable-next-line @typescript-eslint/camelcase
    person_id: personId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    workspace_id: workspaceId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    theft_status: theftStatus,
    // eslint-disable-next-line @typescript-eslint/camelcase
    sort_by: sortBy,
    // eslint-disable-next-line @typescript-eslint/camelcase
    sort_order: sortOrder,
    // eslint-disable-next-line @typescript-eslint/camelcase
    in_trash: inTrash,
    // eslint-disable-next-line @typescript-eslint/camelcase
    created_after: createdAfter,
    // eslint-disable-next-line @typescript-eslint/camelcase
    created_before: createdBefore,
  };

  if (unitId) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.unit_id = unitId;
  }

  return { url, params };
}

export const actions: ActionTree<OccurrenceState, RootState> = {
  fetchOccurrences(
    { commit, state, rootGetters },
  ): Promise<OccurrencePreviewPage | undefined> {
    return new Promise((resolve, reject) => {
      if (!state.occurrenceIteratorSettings) {
        resolve(undefined);
        return;
      }

      const queryOption = state.occurrenceIteratorSettings;
      const { url, params } = getRequestOccurrencesParamsAndUrl(queryOption);

      const headers: AxiosRequestHeaders = { Authorization: rootGetters.authorizationToken };

      if (queryOption?.workspaceId) {
        headers.workspaceId = queryOption.workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers,
        params,
      };

      if (rootGetters.isDemoMode) {
        getDemoOccurrences().then((data) => {
          commit(types.GET_FACEID_OCCURRENCES_SUCCESS, data);
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_OCCURRENCES_SUCCESS, data);
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchOccurrence({ dispatch, getters }, id): Promise<Occurrence> {
    return new Promise((resolve) => {
      const cachedOccurrence = getters.getOccurrence(id);

      if (isCachedDataValid(cachedOccurrence)) {
        resolve(cachedOccurrence);
      } else {
        resolve(dispatch('fetchOccurrenceHelper', id));
      }
    });
  },
  fetchOccurrenceHelper({ commit, rootGetters }, id): Promise<Occurrence> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/occurrences/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoOccurrence(id).then((occurrence) => {
          const output = prepareOccurrenceToBeCached(occurrence);

          commit(types.GET_FACEID_OCCURRENCE_SUCCESS, output);
          resolve(output);
        });
      } else {
        axios(requestOptions).then((response) => {
          let { data: occurrence } = response as { data: Occurrence };

          occurrence = prepareOccurrenceToBeCached(occurrence);
          commit(types.GET_FACEID_OCCURRENCE_SUCCESS, occurrence);
          resolve(occurrence);
        }).catch((error) => reject(error));
      }
    });
  },
  patchOccurrence({ commit, rootGetters },
    { occurrenceId, updateOccurrenceParams, notificationsToAdd }): Promise<Occurrence> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/occurrences/${occurrenceId}/`,
        params: updateOccurrenceParams,
        data: {
          notifications: notificationsToAdd,
        },
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        let { data: occurrence } = response as { data: Occurrence };

        occurrence = prepareOccurrenceToBeCached(occurrence);
        commit(types.PATCH_FACEID_OCCURRENCE_SUCCESS, occurrence);
        resolve(occurrence);
      }).catch((error) => reject(error));
    });
  },
  deleteOccurrence({ commit, rootGetters }, id): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/occurrences/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions)
        .then(() => {
          commit(types.DELETE_FACEID_OCCURRENCE_SUCCESS, id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadOccurrencesReport({ commit, state, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.DOWNLOAD_FACEID_OCCURRENCES_REQUEST);

      if (!state.occurrenceIteratorSettings) {
        resolve(undefined);
        return;
      }

      const queryOption = state.occurrenceIteratorSettings;
      const { url, params } = getRequestOccurrencesParamsAndUrl(queryOption);

      delete params.skip;
      delete params.limit;

      const { locale } = queryOption;

      if (locale) {
        params.locale = locale;
      }

      const requestOptions: AxiosRequestConfig = {
        url: `${url}reports`,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        params,
        responseType: 'blob',
      };

      axios(requestOptions).then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = path;
        link.setAttribute('download', 'deconve_occurrences_report.csv');
        document.body.appendChild(link);
        link.click();

        commit(types.DOWNLOAD_FACEID_OCCURRENCES_SUCCESS);
        resolve();
      }).catch((error) => {
        commit(types.DOWNLOAD_FACEID_OCCURRENCES_FAILURE, error);
        reject(error);
      });
    });
  },

  async addOccurrenceFiles({ rootGetters }, { occurrenceId, files }): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Promise.all(files.map(async (file: FileInfo) => {
          const { name: fileName } = file;

          const fileInfo: FileUploadInfoArray = await
          getUrlToAddOccurrenceFile(rootGetters.authorizationToken, occurrenceId, fileName);

          return Promise.all(fileInfo.map(async (info: FileUploadInfo) => {
            const { url_to_upload: urlToUpload } = info;

            await addOccurrenceFile(rootGetters.authorizationToken, urlToUpload, file);
          }));
        })).then(() => {
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteOccurrenceFiles({ rootGetters }, { occurrenceId, fileNames }): Promise<void> {
    return new Promise((resolve, reject) => {
      const deleteOccurrenceFilePromises: Promise<void>[] = [];

      fileNames.forEach((fileName: string) => {
        deleteOccurrenceFilePromises.push(
          deleteOccurrenceFile(rootGetters.authorizationToken, occurrenceId, fileName),
        );
      });

      Promise.all(deleteOccurrenceFilePromises).then(() => {
        resolve();
      }).catch((error) => reject(error));
    });
  },
  setOccurrenceIteratorSettings({ commit }, data) {
    commit('setOccurrenceIteratorSettings', data);
  },
  setOccurrenceEditMode({ commit }) {
    commit('setOccurrenceCurrentViewMode', 0);
  },
  setOccurrenceViewMode({ commit }) {
    commit('setOccurrenceCurrentViewMode', 1);
  },
};

export default actions;
