// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { SuperProfileImages, SuperProfileImagesState, types } from './types';

export const mutations: MutationTree<SuperProfileImagesState> = {
  [types.GET_SUPER_PROFILE_IMAGE_REQUEST](state, data) {
    const superProfileImagesIndex = state.superProfilesImages?.findIndex(
      (profile) => profile.superProfileId === data.superProfileId,
    );

    if (superProfileImagesIndex === -1) {
      const newData: SuperProfileImages = {
        superProfileId: data.superProfileId,
        images: [data.superProfileImage],
        profileImage: '',
      };

      state.superProfilesImages.push(newData);
    } else {
      const { superProfileImage } = data;
      const { images } = state.superProfilesImages[superProfileImagesIndex];

      const newImages = images.concat(superProfileImage);

      state.superProfilesImages[superProfileImagesIndex].images = newImages;
    }
  },
  [types.GET_SUPER_PROFILE_IMAGE_SUCCESS](state, data) {
    const superProfileImagesIndex = state.superProfilesImages?.findIndex(
      (profile) => profile.superProfileId === data.superProfileId,
    );

    const superProfilesImages = state.superProfilesImages[superProfileImagesIndex];
    const imageIndex = superProfilesImages?.images
      .findIndex((image) => image.id === data.superProfileImageData.id);

    if (imageIndex >= 0) {
      state.superProfilesImages[superProfileImagesIndex].images
        .splice(imageIndex, 1, data.superProfileImageData);
    }
  },
  [types.GET_SUPER_PROFILE_IMAGE_FAILURE](state, data) {
    const superProfileImagesIndex = state.superProfilesImages?.findIndex(
      (profile) => profile.superProfileId === data.superProfileId,
    );

    const superProfilesImages = state.superProfilesImages[superProfileImagesIndex];
    const imageIndex = superProfilesImages?.images
      .findIndex((image) => image.id === data.superProfileImageData.id);

    if (imageIndex >= 0) {
      state.superProfilesImages[superProfileImagesIndex].images
        .splice(imageIndex, 1, data.superProfileImageData);
    }
  },
  [types.ADD_SUPER_PROFILE_IMAGE_PROFILE](state, data) {
    const superProfileImagesIndex = state.superProfilesImages.findIndex(
      (profile) => profile.superProfileId === data.superProfileId,
    );

    if (superProfileImagesIndex >= 0) {
      const newData: SuperProfileImages = {
        ...state.superProfilesImages[superProfileImagesIndex],
        profileImage: data.image,
      };

      state.superProfilesImages[superProfileImagesIndex] = newData;
    } else {
      const newData: SuperProfileImages = {
        superProfileId: data.superProfileId,
        images: [],
        profileImage: data.image,
      };

      state.superProfilesImages.push(newData);
    }
  },
  [types.EDIT_SUPER_PROFILE_IMAGE_REQUEST](state, data) {
    const { superProfileImagesIndex, imageIndex } = data;
    const imagesData = state.superProfilesImages[superProfileImagesIndex].images.slice();

    if (imageIndex < imagesData.length) {
      imagesData[imageIndex].imageIsBeingLoaded = true;
      state.superProfilesImages[superProfileImagesIndex].images = imagesData;
    }
  },
  [types.EDIT_SUPER_PROFILE_IMAGE_SUCCESS](state, data) {
    const { superProfileImagesIndex, imageIndex, newImage } = data;
    const imagesData = state.superProfilesImages[superProfileImagesIndex].images.slice();

    if (imageIndex < imagesData.length) {
      const targetImage = state.superProfilesImages[superProfileImagesIndex].images[imageIndex];

      Object.assign(targetImage, { ...newImage, imageIsBeingLoaded: false });
    }
  },
  [types.EDIT_SUPER_PROFILE_IMAGE_FAILURE](state, data) {
    const { superProfileImagesIndex, imageIndex, imageError } = data;
    const imagesData = state.superProfilesImages[superProfileImagesIndex].images.slice();

    if (imageIndex < imagesData.length) {
      const targetImage = state.superProfilesImages[superProfileImagesIndex].images[imageIndex];

      targetImage.error = imageError;
      targetImage.imageIsBeingLoaded = false;
    }
  },
};

export default mutations;
