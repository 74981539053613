// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';

import { UNIT_TYPE } from '@/utils/faceidFilters';
import {
  UnitsState, MonitoredReferenceUnitsPreview, MonitoredNearbyUnitsPreview, types,
} from './types';
import { RootState } from '../../types';
import { getDemoReferenceMonitoringUnits, getDemoNearbyMonitoringUnits } from './demo';

export const actions: ActionTree<UnitsState, RootState> = {
  fetchMonitoredUnits(
    { rootGetters, commit }, { profileId, filtersOptions, isPersonRequest },
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_FACEID_PEOPLE_MONITORING_UNITS_REQUEST);

      const {
        skip,
        limit,
        unitType,
        search,
      } = filtersOptions;

      const params = {
        skip,
        limit,
        // eslint-disable-next-line @typescript-eslint/camelcase
        unit_type: unitType,
        search,
      };

      let url;

      if (isPersonRequest) {
        url = `/faceid/people/${profileId}/actions/monitoring/`;
      } else {
        url = `/faceid/super-profiles/${profileId}/actions/monitoring/`;
      }

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url,
        params,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        if (unitType === UNIT_TYPE.reference) {
          getDemoReferenceMonitoringUnits().then((data: MonitoredReferenceUnitsPreview) => {
            commit(types.GET_FACEID_PEOPLE_REFERENCE_MONITORING_UNITS_SUCCESS, data);
          });
        } else {
          getDemoNearbyMonitoringUnits().then((data: MonitoredNearbyUnitsPreview) => {
            commit(types.GET_FACEID_PEOPLE_NEARBY_MONITORING_UNITS_SUCCESS, data);
          });
        }
      } else {
        axios(requestOptions).then((response) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          const { has_more, items, total } = response.data;

          // eslint-disable-next-line @typescript-eslint/camelcase
          const monitoringInUnits = { hasMore: has_more, items, total };

          if (unitType === UNIT_TYPE.reference) {
            commit(types.GET_FACEID_PEOPLE_REFERENCE_MONITORING_UNITS_SUCCESS, monitoringInUnits);
          } else {
            commit(types.GET_FACEID_PEOPLE_NEARBY_MONITORING_UNITS_SUCCESS, monitoringInUnits);
          }
        }).catch((error) => {
          commit(types.GET_FACEID_PEOPLE_MONITORING_UNITS_ERROR);
          reject(error);
        });
      }
    });
  },
};

export default actions;
