// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosResponse } from 'axios';

import { blobToDataUrl } from '../../../utils/data';
import { RootState } from '../../types';
import {
  SuperProfileImagesState, types,
  SuperProfileImage,
} from './types';

function downloadImage(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'get',
      responseType: 'blob',
    })
      .then((response) => blobToDataUrl(response as AxiosResponse))
      .then((image) => resolve(image))
      .catch((error) => {
        reject(error);
      });
  });
}

export const actions: ActionTree<SuperProfileImagesState, RootState> = {
  fetchSuperProfileImages({ commit, state }, { images, superProfileId }) {
    // cancel images request if the super profile id is on the state
    const superProfileImagesById = state.superProfilesImages.find(
      (profile) => profile.superProfileId === superProfileId,
    );

    const hasImages = superProfileImagesById && superProfileImagesById?.images.length > 0;

    if (hasImages) {
      return;
    }

    // Prepare the super profile image data with face info information to be requested
    const superProfileImages: SuperProfileImage[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images.forEach((image: any) => {
      const superProfileFaces = {
        faces: image.faces,
        selected: image.faces.length > 0 ? 0 : undefined,
      };

      const superProfileImage: SuperProfileImage = {
        id: image.id,
        originalImageUrl: image.original_url,
        mediumImageUrl: image.medium_quality_url,
        thumbnailImageUrl: image.thumbnail_url,
        originalName: image.original,
        mediumName: image.medium_quality,
        thumbnailName: image.thumbnail,
        originalImage: '',
        originalHeight: image.original_height,
        originalWidth: image.original_width,
        mediumImage: '',
        thumbnailImage: '',
        info: superProfileFaces,
        imageIsBeingLoaded: true,
        facesAreBeingLoaded: true,
      };

      superProfileImages.push(superProfileImage);
      commit(types.GET_SUPER_PROFILE_IMAGE_REQUEST, { superProfileImage, superProfileId });
    });

    // Download the images
    superProfileImages.forEach((superProfileImage) => {
      const superProfileImageData = { ...superProfileImage };

      // Get thumbnail image version
      downloadImage(superProfileImage.thumbnailImageUrl)
        .then((image) => {
          if (image) {
            superProfileImageData.thumbnailImage = image;
            superProfileImageData.imageIsBeingLoaded = false;
            superProfileImageData.facesAreBeingLoaded = false;

            commit(types.GET_SUPER_PROFILE_IMAGE_SUCCESS,
              { superProfileImageData, superProfileId });

            // Getting the medium image quality to improve the face image quality in the person
            // profile
            downloadImage(superProfileImage.mediumImageUrl)
              .then((mediumImage) => {
                if (mediumImage) {
                  superProfileImageData.mediumImage = mediumImage;
                  commit(types.GET_SUPER_PROFILE_IMAGE_SUCCESS,
                    { superProfileImageData, superProfileId });
                }
              })
              .catch((error) => {
                superProfileImageData.error = error;
                commit(types.GET_SUPER_PROFILE_IMAGE_FAILURE,
                  { superProfileImageData, superProfileId });
              });
          }
        })
        .catch((error) => {
          superProfileImageData.error = error;
          superProfileImageData.imageIsBeingLoaded = false;
          superProfileImageData.facesAreBeingLoaded = false;
          commit(types.GET_SUPER_PROFILE_IMAGE_FAILURE,
            { superProfileImageData, superProfileId });
        });
    });
  },

  getOriginalSuperProfileImage({ commit, state }, { superProfileId, imageId }): Promise<void> {
    return new Promise((resolve, reject) => {
      const superProfileImagesIndex = state.superProfilesImages.findIndex(
        (profile) => profile.superProfileId === superProfileId,
      );
      const imagesData = state.superProfilesImages[superProfileImagesIndex].images.slice();

      if (superProfileImagesIndex === -1) {
        return;
      }

      const imageIndex = imagesData.findIndex((image) => image.id === imageId);
      const newImage = imagesData[imageIndex];

      commit(types.EDIT_SUPER_PROFILE_IMAGE_REQUEST, { superProfileImagesIndex, imageIndex });

      downloadImage(newImage.originalImageUrl)
        .then((image) => {
          if (image) {
            newImage.originalImage = image;
            const updatedImage = { superProfileImagesIndex, imageIndex, newImage };

            commit(types.EDIT_SUPER_PROFILE_IMAGE_SUCCESS, updatedImage);
          }

          resolve();
        }).catch((err) => {
          const updatedImage = { superProfileImagesIndex, imageIndex, imageError: err };

          commit(types.EDIT_SUPER_PROFILE_IMAGE_FAILURE, updatedImage);
          reject();
        });
    });
  },

  fetchSuperProfileImage({ commit, state }, { superProfileId, profileImageUrl }): Promise<void> {
    return new Promise((resolve, reject) => {
      const superProfileImagesById = state.superProfilesImages.find(
        (profile) => profile.superProfileId === superProfileId,
      );

      if (superProfileImagesById?.profileImage) {
        resolve();
        return;
      }

      downloadImage(profileImageUrl)
        .then((image) => {
          const data = { image, superProfileId };

          commit(types.ADD_SUPER_PROFILE_IMAGE_PROFILE, data);

          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
};

export default actions;
