// Copyright (C) 2025 Deconve Technology. All rights reserved.

import { datadogRum } from '@datadog/browser-rum';

const applicationId = process.env.VUE_APP_DATADOG_RUM_APPLICATION_ID;

// To avoid error in local development
if (applicationId) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    proxy: (options) => `${process.env.VUE_APP_DATADOG_RUM_PROXY}${options.path}?${options.parameters}`,
    service: 'dashboard-app',
    env: process.env.VUE_APP_DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.BITBUCKET_TAG,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 20, // Only 20%*50% = 10% of the session will be recorded
    defaultPrivacyLevel: 'mask-user-input',
  });
}
