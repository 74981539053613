// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { GetterTree } from 'vuex';
import { ApiRequestError, RootState } from '../../types';

import {
  History,
  Face,
  PersonPreviewPage,
  PeopleState,
  Person,
  Tag,
} from './types';

export const getters: GetterTree<PeopleState, RootState> = {
  personTags(state): Array<Tag> | undefined {
    return state.person.tags;
  },
  personList(state): PersonPreviewPage {
    return state.peoplePreviewList;
  },
  personFinderImage(state): string {
    return state.peopleByImageList.file.image;
  },
  personFinderImageName(state): string {
    return state.peopleByImageList.file.name;
  },
  personFinderPeople(state): { 'id': string }[] | [] {
    const { selectedPeople } = state.peopleByImageList;

    if (!selectedPeople || selectedPeople?.length === 0) return [];

    const { page, itemsPerPage } = state.peopleByImageList.filters;
    const firstIndex = (page * itemsPerPage) - itemsPerPage;
    const lastIndex = Math.min(page * itemsPerPage, selectedPeople.length);

    const people: {'id': string}[] = selectedPeople.slice(firstIndex, lastIndex);

    return people;
  },
  personFinderPeopleTotal(state): number {
    const { selectedFaceIndex } = state.peopleByImageList.file;

    if (selectedFaceIndex >= 0) {
      const { selectedPeople } = state.peopleByImageList;

      if (selectedPeople) return selectedPeople.length;
    }

    return -1;
  },
  personFinderFaces(state): Face[] | undefined {
    return state.peopleByImageList.file?.faces;
  },
  personFinderSelectedFaceIndex(state): number | undefined {
    return state.peopleByImageList.file.selectedFaceIndex;
  },
  personIdentificationScore(state): number {
    return state.peopleByImageList.filters.selectedScore;
  },
  personFinderFaceSelectedInfo(state): Face | undefined {
    const { faces, selectedFaceIndex } = state.peopleByImageList.file;

    if (faces && faces.length > 0 && selectedFaceIndex !== undefined) {
      return faces[selectedFaceIndex];
    }

    return undefined;
  },
  numberOfPeople: (state): number => state.peoplePreviewList.total,
  person(state): Person | undefined {
    return state.person.data;
  },
  isLoadingPerson(state): boolean {
    return state.person.isLoading;
  },
  addPersonError(state): AxiosError | ApiRequestError | undefined {
    const { error } = state.person as { error: AxiosError };

    if (error?.response) {
      // Request made and server responded
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return state.person.error;
  },
  getPerson: (state) => (personId: string): Person | undefined => (
    state.people.find(({ id }) => id === personId)
  ),
  getNotificationPerson: (state) => (personId: string): History[] | undefined => {
    const person = state.people.find(({ id }) => id === personId);

    return person?.history;
  },
  getPersonIteratorSettings(state) {
    return state.personIteratorSettings;
  },
  isDownloadingPeople(state): boolean {
    return state.peopleDownloadState.isLoading;
  },
  peopleDownloadError(state): AxiosError | undefined {
    return state.peopleDownloadState.error;
  },
  getPersonFalseNegatives(state) {
    return state.personFalseNegatives;
  },
};

export default getters;
