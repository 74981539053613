// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import {
  UnitsIteratorSettings, UnitsPreviewPage, UnitsState, types, Unit,
} from './types';

export const mutations: MutationTree<UnitsState> = {
  [types.GET_UNITS_PAGE_SUCCESS](state, unitsPage: UnitsPreviewPage) {
    state.unitsPage = unitsPage;
  },
  [types.GET_UNITS_SUCCESS](state, units: Unit[]) {
    state.units = units;
  },
  [types.DOWNLOAD_FACEID_UNITS_REQUEST](state) {
    state.unitsDownloadState.isLoading = true;
  },
  [types.DOWNLOAD_FACEID_UNITS_SUCCESS](state) {
    state.unitsDownloadState.isLoading = false;
  },
  [types.DOWNLOAD_FACEID_UNITS_FAILURE](state, error) {
    state.unitsDownloadState.isLoading = false;
    state.unitsDownloadState.error = error;
  },
  setUnitsIteratorSettings(state, settings: UnitsIteratorSettings) {
    state.unitsIteratorSettings = settings;
  },
};

export default mutations;
