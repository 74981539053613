// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import {
  NotificationState, Notification, NotificationPreviewPage, types,
} from './types';

import { hasNotificationChanged } from './utils';

const kMaxBufferSize = 128;

export const mutations: MutationTree<NotificationState> = {
  [types.GET_FACEID_NOTIFICATIONS_SUCCESS](state, data: NotificationPreviewPage) {
    state.notificationPreviewPage.items = data.items;
    state.notificationPreviewPage.total = data.total;
  },
  [types.GET_FACEID_NOTIFICATION_SUCCESS](state, data: Notification) {
    const { id: notificationId } = data;
    const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

    if (notificationIndex >= 0) {
      // To avoid to propagate signals when the notification is reviewed and the trashed, we check
      // if it has changed before to update the store
      const currentData = state.notifications[notificationIndex];

      if (hasNotificationChanged(currentData, data)) {
        state.notifications.splice(notificationIndex, 1, data);
      }
    } else {
      const numberOfNotifications = state.notifications.push(data);

      if (numberOfNotifications > kMaxBufferSize) {
        state.notifications.shift();
      }
    }
  },
  [types.DELETE_FACEID_NOTIFICATION_SUCCESS](state, notificationId) {
    const { items } = state.notificationPreviewPage;

    if (items) {
      const notificationIndex = items.findIndex((notification) => (
        notification.id === notificationId
      ));

      if (notificationIndex >= 0) {
        state.notificationPreviewPage.items.splice(notificationIndex, 1);
        state.notificationPreviewPage.total -= 1;
      }
    }

    const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

    if (notificationIndex >= 0) {
      state.notifications.splice(notificationIndex, 1);
    }
  },
  [types.PUT_FACEID_NOTIFICATION_SUCCESS](state, data) {
    const { id: notificationId } = data;
    const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

    if (notificationIndex >= 0) {
      // To avoid to propagate signals when the notification is reviewed and trashed, for example,
      // we check if it has changed before to update the store
      const currentData = state.notifications[notificationIndex];

      if (hasNotificationChanged(currentData, data)) {
        state.notifications.splice(notificationIndex, 1, data);
      }
    } else {
      const numberOfNotifications = state.notifications.push(data);

      if (numberOfNotifications > kMaxBufferSize) {
        state.notifications.shift();
      }
    }
  },
  [types.DOWNLOAD_FACEID_NOTIFICATIONS_REQUEST](state) {
    state.notificationsDownloadState.isLoading = true;
  },
  [types.DOWNLOAD_FACEID_NOTIFICATIONS_SUCCESS](state) {
    state.notificationsDownloadState.isLoading = false;
  },
  [types.DOWNLOAD_FACEID_NOTIFICATIONS_FAILURE](state, error) {
    state.notificationsDownloadState.isLoading = false;
    state.notificationsDownloadState.error = error;
  },
  setNotificationIteratorSettings(state, data) {
    state.notificationIteratorSettings = data;
  },
};

export default mutations;
